import React, { Fragment, ReactNode } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
// BS
interface AccordionCardProps {
    headerTitle: string;
    className?: string;
    handleFilter?: () => void;
    cssAccordion?: string;
    showBorder?: boolean;
    children?: ReactNode;
    cssCardBody?: string;
    cssAccordionContent?: string;
}


const AccordionCard: React.FC<AccordionCardProps> = ({ headerTitle, children, className, handleFilter, cssAccordion, showBorder = true, cssCardBody, cssAccordionContent = "" }) => {

    const CustomToggle: React.FC<{ eventKey: string; children?: ReactNode; }> = ({ eventKey, children }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('totally custom!'),
        );

        return (
            <div onClick={decoratedOnClick}>
                {children}
            </div>
        );
    }

    return (
        <Fragment>
            <Accordion className={cssAccordion} defaultActiveKey="0">
                <Card className={`${showBorder ? "border-b-5px border-primary" : ""} ${className}`}>
                    <CustomToggle eventKey="0">
                        <Card.Header className="d-flex flex-wrap align-items-center justify-content-between cursor-pointer fw-bold fs-4 py-3">
                            <div className="fw-bold fs-4">{headerTitle}

                            </div>
                            <i className="far fa-chevron-down" style={{ fontSize: 18, flexShrink: 0 }}></i>
                        </Card.Header>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="0" className={`${cssAccordionContent}`}>
                        <>
                            <Card.Body className={`py-3 ${cssCardBody}`}>
                                {children}
                            </Card.Body>
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Fragment>
    );
};

export default AccordionCard;
