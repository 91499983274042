//định dạng sđt
const formatPhoneNumber = (phoneNumberString: any) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "*** *** " + match[3];
    // return match[1] + " " + match[2] + " " + match[3];
  }
  return null;
};
//định dạng sđt ko che
const formatShowPhoneNumber = (phoneNumberString: any) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + " " + match[2] + " " + match[3];
    // return match[1] + " " + match[2] + " " + match[3];
  }
  return null;
};
//check định dạng mail
function validateEmail(email: string) {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com)$/;
  return re.test(email);
}
//ĐỊNH DẠNG GIÁ
const formatPrice = (val: number | string | undefined) => {
  if (val !== undefined && val !== null) {
    let x = val.toString();
    // return v.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  } else {
    return "";
  }
};
//Chuyển sang số la mã
export const convertToRoman = (num: number) => {
  if (isNaN(num))
    return NaN;
  var digits = String(+num).split(""),
    key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
           "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
           "","I","II","III","IV","V","VI","VII","VIII","IX"],
    roman = "",
    i = 3;
  while (i--) {
    const digit = +(digits?.pop() ?? 0);
    roman = (key[digit ? digit + (i * 10) : 0] || "") + roman;
  }
  return Array((+digits.join("") || 0) + 1).join("M") + roman;
}

//ĐỊNH DẠNG GIÁ dấu ,
const formatPricestring = (val: number | string | undefined) => {
  if (val) {
    let x = val.toString();
    // return v.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    var parts = x.toString().split(",");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  if (val === 0) {
    return '0';
  }

  return "NaN";
};

interface CheckValueOptions {
  isEmpty?: boolean;
  isNull?: boolean;
}

/**
 * Kiểm tra value có phải là null hoặc empty hay không, nếu phải thì trả về valueIsFail
 * @param value giá trị cần xét
 * @param valueIsFail giá trị trả về khi value là rỗng hoặc null
 * @param allow cấu hình cho phép giá trị mang giá trị gì
 * @returns
 */
const parseValue = (
  value: any,
  valueIsFail: any,
  allow?: CheckValueOptions
) => {
  if (!allow?.isNull) {
    if (value === null || value === "null") {
      return valueIsFail;
    }
  }
  if (!allow?.isEmpty) {
    if (["string", "object"].includes(typeof value) && value.length === 0) {
      return valueIsFail;
    }
  }
  return value;
};

/**
 * Kiểm tra giá trị thành phần của params có phải là null hoặc empty hay không, nếu phải thì trả về valueIsFail
 * @param params tham số cần xét
 * @param valueIsFail giá trị thay đổi của giá trị thành phần khi giá trị đó là rỗng hoặc null
 * @param allow cấu hình cho phép giá trị mang giá trị gì
 * @returns
 */
const paserParams = (
  params: any,
  valueIsFail: any,
  allow?: CheckValueOptions
) => {
  for (let i in params) {
    if (params[i] && typeof params[i] === "object") {
      params[i] = paserParams(params[i], valueIsFail, allow);
    } else {
      params[i] = parseValue(params[i], valueIsFail, allow);
    }
  }
  return params;
};

const dataPagination = (
  totalRow: number,
  rowNumber: number,
  currentPage: number
) => {
  var tongTrang =
    Math.ceil(totalRow / rowNumber) === 0 ? 1 : Math.ceil(totalRow / rowNumber);

  var tongDongTrangCuoi =
    totalRow - rowNumber * Math.floor(totalRow / rowNumber);
  var hienThiTu = rowNumber * currentPage;
  var hienThiDen = rowNumber * (currentPage + 1);
  return {
    totalPage: tongTrang,
    currentPage: currentPage,
    totalLastLine: tongDongTrangCuoi,
    hienThiTu: hienThiTu,
    hienThiDen: hienThiDen,
  };
};

// PERMISSION and MENU
interface PermissionItem extends Permission {
  child?: PermissionMap;
  pathobj?: string[];
}

interface PermissionMap {
  [key: string]: PermissionItem;
}

interface MenuInfo {
  idMenu: string;
  tenMenu: string;
  moTa: string | null;
  url: string | null;
  apiUrl: string | null;
  stt: number | null;
  hinhAnh: string | null;
  hoatDong: boolean;
  child?: PermissionTree;
  pathobj?: string[];
}

export interface PermissionTree {
  [key: string]: MenuInfo;
}

export interface Permission {
  idMenu: string;
  idMenuCha: string | null;
  tenMenu: string;
  moTa: string | null;
  url: string | null;
  apiUrl: string | null;
  stt: number | null;
  hinhAnh: string | null;
  hoatDong: boolean;
}

// function convert Permission To Tree
function convertPermissionToTree(permission: Permission[]) {
  const permissionTree: PermissionTree = {};

  // convert permission change idMenu to key and value is Permission
  const permissionMap: PermissionMap = {};
  permission.forEach((item) => {
    permissionMap[item.idMenu] = item;
  });

  // convert permission to tree
  permission.forEach((item) => {
    const path = item.url?.split("/")?.at(-1);
    const itemMenu = permissionMap[item.idMenu];
    if (item.idMenuCha) {
      if (!itemMenu.pathobj) {
        itemMenu.pathobj = [];
      }
      if (path && !itemMenu.pathobj.includes(path)) {
        itemMenu.pathobj = [...itemMenu.pathobj, path];
      }
      var parent: PermissionItem | null = permissionMap[item.idMenuCha];
      if (parent) {
        if (parent.idMenu === "6259deff-3064-4d24-9dcc-1d07d6fff69a") {
        }
        if (!parent.child) {
          parent.child = {};
        }
        parent.child[item.idMenu] = item;
        // push path into pathobj all parent
        if (path) {
          while (parent?.idMenu) {
            if (!parent.pathobj?.includes(path)) {
              parent.pathobj = [...(parent.pathobj ?? []), path];
            }

            if (parent.idMenuCha && parent.idMenu !== parent.idMenuCha) {
              parent = permissionMap[parent.idMenuCha];
            } else {
              parent = null;
            }
          }
        }
      }
    } else {
      permissionMap[item.idMenu].pathobj = path
        ? [...(permissionMap[item.idMenu].pathobj ?? []), path]
        : [];
      permissionTree[item.idMenu] = permissionMap[item.idMenu];
    }
  });

  return permissionTree;
}

export {
  formatPhoneNumber,
  validateEmail,
  formatPrice,
  formatShowPhoneNumber,
  parseValue,
  paserParams,
  dataPagination,
  formatPricestring,
  convertPermissionToTree,
};
export const getZnsStatus = (detail?: string) =>{
  let status: string | undefined = ''
  switch(detail){
    case 'OA or App does not have permission to access this template':
      status = 'Ứng dụng không có quyền truy cập vào mẫu tin nhắn này'
      break;
    case 'Template data empty':
      status = 'Dữ liệu mẫu tin nhắn trống'
      break;
    case 'Access token invalid':
      status = 'Chưa cấu hình token nhắn tin'
      break;
    case 'Success':
      status = 'Thành công'
      break;
    case 'Unknown error':
      status = 'Lỗi không xác định'
      break;
    case 'App secret key invalid':
      status = 'Chưa cấu hình token nhắn tin'
      break;
    case 'Phone number invalid':
      status = 'Số điện thoại không hợp lệ'
      break;
    case 'Template ID invalid':
      status = 'Mã mẫu tin nhắn không hợp lệ'
      break;
    case 'Zalo version unsupported':
      status = 'Phiên bản Zalo không hỗ trợ'
      break;
    case 'Out of quota':
      status = 'Hết lượt gửi tin nhắn'
      break;
    case 'Text invalid':
      status = 'Nội dung tin nhắn không hợp lệ'
      break;
    case 'User is inactive, or rejects the message, or using an outdated Zalo version, or other internal errors':
      status = 'Người dùng không hoạt động, hoặc từ chối tin nhắn, hoặc sử dụng phiên bản Zalo cũ, hoặc lỗi nội bộ khác'
      break;
    case 'Zalo account not existed':
      status = 'Tài khoản Zalo không tồn tại'
      break;
    case 'Account can not receive message':
      status = 'Tài khoản không thể nhận tin nhắn'
      break;
    case 'ZCA charge failure':
      status = 'Lỗi thu tiền'
      break;
    case 'template data type is not define':
      status = 'Loại dữ liệu mẫu tin nhắn không được xác định'
      break;
    default:
      status = detail
      break;
  }
  return status
}