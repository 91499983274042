import { paserParams } from "../until";
import axiosClient from "./axiosClient";

//quyền
export interface Permission {
  idNguoiDung?: string;
  idQuyen?: string;
  tenQuyen?: string;
}
export interface PermissionList {
  data: Permission[];
  success?: boolean;
}

export interface ResponseStatus {
  success?: boolean;
  data?: string;
}

export interface AddPermission {
  data: {
    idQuyen?: string;
  };
}
export interface UpdatePermission {
  data: Permission;
}

//danh sách
export const getListUserFunction = async (IdNguoiDung: string = "") => {
  const params = {
    search: {
      IdNguoiDung,
    },
    Sort: {
      TenQuyen: "ASC",
    },
  };
  const path = "quyen/quyen-nguoi-dung";
  const result: PermissionList = await axiosClient.get(path, {
    params: paserParams(params, undefined),
  });
  return result;
};
//xác nhận quyền người dùng
export const confilmPermission = async (
  IdQuyen: Array<any> = [],
  IdNguoiDung: string = ""
) => {
  const params = {
    IdQuyen: IdQuyen,
    IdNguoiDung,
  };
  const path = "quyen/quyen-nguoi-dung";
  const result: PermissionList = await axiosClient.post(
    path,
    paserParams(params, undefined)
  );
  return result;
};
