import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// BS
interface MenuItemProps {
  className?: string;
  onClick?: () => void;
  to?: any;
  icon?: string;
  title?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  className,
  onClick,
  to,
  icon,
  title,
}) => {
  return (
    <Fragment>
      <li className={className} onClick={onClick}>
        <Link to={to} className="ai-icon">
          <i className={icon} style={{ width: 28 }}></i>
          <span className="nav-text">{title}</span>
        </Link>
      </li>
    </Fragment>
  );
};

export default MenuItem;
